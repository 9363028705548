import React, { useEffect, useState } from 'react';
import { t } from '@/utility/localization';
import { usePathname } from 'next/navigation';
import { getMemberCommunityPageRoute } from '@/utility/routesHelper';
import { TABS_ID } from '../constants';
import AnimatedTabLinks from '@/components/common/AnimatedTabLinks';
import {
  MEMBER_TAB_CLICK_ABOUT,
  MEMBER_TAB_CLICK_CHALLENGES,
  MEMBER_TAB_CLICK_CHAT,
  MEMBER_TAB_CLICK_EVENTS,
  MEMBER_TAB_CLICK_HOME,
  MEMBER_TAB_CLICK_MEMBERS,
  MEMBER_TAB_CLICK_PRODUCT
} from '@/utility/analyticsConsts';
import { trackGAEvent } from '@/utility/analytics';

const Tabs = ({
  communityData,
  setHasScrollPastBanner,
  isCommunityAdmin,
  isCommunityMember,
  showHideTabsData,
  genericTrackingFields,
  communityDetailsRef
}) => {
  const { slug: communitySlug, config } = communityData || {};
  const { hideMemberCount } = config || {};

  const pathname = usePathname();
  const HOME_ROUTE =
    isCommunityAdmin || isCommunityMember
      ? getMemberCommunityPageRoute(communitySlug)
      : communitySlug;

  const COMMUNITY_LANDING_PAGE_TABS = [
    {
      id: TABS_ID.HOME,
      label: t('home'),
      link: HOME_ROUTE,
      show: true,
      eventId: MEMBER_TAB_CLICK_HOME
    },
    {
      id: TABS_ID.PRODUCTS,
      label: t('products'),
      link: `${communitySlug}/products`,
      show: showHideTabsData[TABS_ID.PRODUCTS],
      eventId: MEMBER_TAB_CLICK_PRODUCT
    },
    {
      id: TABS_ID.CHALLENGES,
      label: t('challenges'),
      link: `${communitySlug}/challenges`,
      show: showHideTabsData[TABS_ID.CHALLENGES],
      eventId: MEMBER_TAB_CLICK_CHALLENGES
    },
    {
      id: TABS_ID.EVENTS,
      label: t('events'),
      link: `${communitySlug}/events`,
      show: showHideTabsData[TABS_ID.EVENTS],
      eventId: MEMBER_TAB_CLICK_EVENTS
    },
    {
      id: TABS_ID.CHAT,
      label: t('chat'),
      link: `${communitySlug}/chat`,
      show: showHideTabsData[TABS_ID.CHAT],
      eventId: MEMBER_TAB_CLICK_CHAT
    },
    {
      id: TABS_ID.MEMBERS,
      label: t('members'),
      link: `${communitySlug}/members`,
      show: !hideMemberCount,
      eventId: MEMBER_TAB_CLICK_MEMBERS
    },
    {
      id: TABS_ID.ABOUT,
      label: t('about'),
      link: `${communitySlug}/about`,
      show: showHideTabsData[TABS_ID.ABOUT],
      eventId: MEMBER_TAB_CLICK_ABOUT
    }
  ];

  const [activeTab, setActiveTab] = useState(TABS_ID.HOME);

  useEffect(() => {
    const handleShowSticky = () => {
      const pastScrollBannerHeight =
        communityDetailsRef?.current?.clientHeight;

      if (window.scrollY > pastScrollBannerHeight) {
        setHasScrollPastBanner(true);
      } else {
        setHasScrollPastBanner(false);
      }
    };

    window.addEventListener('scroll', handleShowSticky);
    return () => {
      window.removeEventListener('scroll', handleShowSticky);
    };
  }, []);

  useEffect(() => {
    const pathName = pathname;

    const splitPathname = pathName.split('/');

    if (splitPathname.length < 3) {
      setActiveTab(TABS_ID.HOME);
    } else {
      // home -> HOME to check if value exists in TABS_ID map
      const lastItemInPathName = splitPathname[2].toUpperCase();
      setActiveTab(TABS_ID[lastItemInPathName]);
    }
  }, [COMMUNITY_LANDING_PAGE_TABS, pathname]);

  const trackTabClick = (eventId) => {
    trackGAEvent(eventId, { ...genericTrackingFields });
  };

  return (
    <div className="c-CommunityPage-Tabs w-full">
      <div className="flex justify-center ">
        <AnimatedTabLinks
          tabs={COMMUNITY_LANDING_PAGE_TABS}
          tabOnClick={trackTabClick}
          activeTabId={activeTab}
          showHoveStrike
        />
      </div>
    </div>
  );
};

export default Tabs;
