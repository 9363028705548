import { useMemo, useRef, useState } from 'react';
import usePixelTracking from './usePixelTracking';
import useUserCommunities, {
  getUserRoleInCommunity
} from './useUserCommunities';
import { useRouter } from 'next/navigation';
import {
  CM_PORTAL_HOMEPAGE_ROUTE,
  MEMBER_PORTAL_DEFAULT_PAGE_ROUTE,
  getDiscountCodeFromUrlParams
} from '@/utility/routesHelper';
import { markInitialSignupOverlaySeen } from '@/features/community/utils';
import useCommunityPricingData from './useCommunityPricingData';
import useQueryParams from './useQueryParams';

/**
 * Custom hook for retrieving common public page data.
 * @param {Object} props - The props object.
 * @param {Object} props.communityInfo - The community information object.
 * @returns {Object} - The common public page data.
 * @property {boolean} fetchingUserCommunities - Indicates if user communities are being fetched.
 * @property {Array} userCommunities - The user communities.
 * @property {boolean} isPendingApproval - Indicates if the user is pending approval in the community.
 * @property {boolean} isCommunityAdmin - Indicates if the user is a community admin.
 * @property {boolean} isCommunityMember - Indicates if the user is a community member.
 */
const useCommonPublicPageData = (props) => {
  const router = useRouter();
  const { currentRouterQuery } = useQueryParams();
  const { communityInfo } = props;
  const communityId = communityInfo?._id;
  const communityCode = communityInfo?.code;
  const isDemoCommunity = communityInfo?.isDemo;

  const [isSignUpModalOpened, setIsSignUpModalOpened] = useState(false);
  const [loadingUserRoles, setLoadingUserRoles] = useState(true);
  const signUpPopupRef = useRef();
  const discountCodeFromQueryParams =
    getDiscountCodeFromUrlParams(currentRouterQuery);

  // Community pricing data
  const { pricingData, priceTagText } = useCommunityPricingData({
    community: communityInfo
  });

  // Load pixel if data is available.
  usePixelTracking({
    communityId,
    trackingPixels: communityInfo?.trackingPixels,
    postInitProcess: {
      trackPageView: true
    }
  });

  // Identify the type of user in the community.
  const {
    fetchingUserCommunities,
    isUserCommunitiesFetched,
    userCommunities,
    mutate: mutateUserCommunities
  } = useUserCommunities();
  const { isPendingApproval, isCommunityAdmin, isCommunityMember } =
    useMemo(() => {
      const {
        isPendingApproval,
        isCommunityAdmin,
        isCommunityMember,
        communityFound
      } = getUserRoleInCommunity(communityCode, userCommunities);
      if (communityFound) {
        setLoadingUserRoles(false);
      }
      return { isPendingApproval, isCommunityMember, isCommunityAdmin };
    }, [communityCode, userCommunities]);

  // nav helper functions
  const goToMemberPortal = () => {
    let route = MEMBER_PORTAL_DEFAULT_PAGE_ROUTE;

    if (isCommunityMember) route += `?activeCommunityId=${communityId}`;

    router.push(route);
  };

  const goToCMP = () => {
    const path = `${CM_PORTAL_HOMEPAGE_ROUTE}?activeCommunityId=${communityId}`;
    router.push(path, path, { locale: false });
  };

  const openSignupModal = () => setIsSignUpModalOpened(true);

  const onSignUpPopupClose = () => {
    setIsSignUpModalOpened(false);
    markInitialSignupOverlaySeen(communityId);
  };

  return {
    // community data
    isDemoCommunity,

    // user data
    isUserCommunitiesFetched,
    loadingUserRoles,
    fetchingUserCommunities,
    userCommunities,
    isPendingApproval,
    isCommunityAdmin,
    isCommunityMember,
    mutateUserCommunities,

    // nav helper functions
    goToMemberPortal,
    goToCMP,

    // signup popup
    isSignUpModalOpened,
    setIsSignUpModalOpened,
    openSignupModal,
    signUpPopupRef,
    onSignUpPopupClose,
    discountCodeFromQueryParams,
    pricingData,
    priceTagText
  };
};

export default useCommonPublicPageData;
